import React from "react";
import { Link } from "gatsby";

import { useStyletron } from "baseui";
import { HeadingLevel, Heading } from "baseui/heading";
import { Paragraph1, Label1 } from "baseui/typography";
import { ListItem, ListItemLabel, ARTWORK_SIZES } from "baseui/list";
import { Check } from "baseui/icon";

import Page from "../components/internal-page";
import CheckNPD from "../components/check-npd";

import { PROGRAMS } from "../data/programs";
import { StyledLink } from "baseui/link";

const SelfEmployed = () => {
  const [css, theme] = useStyletron();

  const PROGRAM = PROGRAMS.filter(
    (program) => program.name === "«Самозанятые граждане»"
  );
  const {
    name,
    maxAmount,
    interestRate,
    loanTerm,
    isGraceDisabled,
    description,
  } = PROGRAM[0];
  return (
    <Page
      title={`Займы под ${interestRate.toLocaleString(
        "ru-RU"
      )}% для самозанятых граждан Архангельской области`}
    >
      <HeadingLevel>
        <HeadingLevel>
          <Heading styleLevel={4}>Условия программы {name}</Heading>
          <Label1>
            Сумма до {(maxAmount || 5_000_000).toLocaleString("ru-RU")} руб.
          </Label1>
          <Paragraph1>
            Ставка: {interestRate.toLocaleString("ru-RU")}%, Срок: до{" "}
            {loanTerm || 24} мес., Льготный период:{" "}
            {isGraceDisabled ? "нет" : `до ${(loanTerm || 24) - 1} мес.`}
          </Paragraph1>
          <Label1>Участники программы</Label1>
          <Paragraph1>{description}</Paragraph1>
        </HeadingLevel>
        <HeadingLevel>
          <Heading styleLevel={4}>Требования к заявителю</Heading>
          <ListItem
            artwork={(props) => (
              <Check
                className={css({ color: theme.colors.positive400 })}
                {...props}
              />
            )}
            artworkSize={ARTWORK_SIZES.LARGE}
          >
            <ListItemLabel>
              Физическое лицо, применяющее «Налог на профессиональный доход»
            </ListItemLabel>
          </ListItem>
          <ListItem
            artwork={(props) => (
              <Check
                className={css({ color: theme.colors.positive400 })}
                {...props}
              />
            )}
            artworkSize={ARTWORK_SIZES.LARGE}
          >
            <ListItemLabel>
              Регистрация на территории Архангельской области
            </ListItemLabel>
          </ListItem>
          <ListItem
            artwork={(props) => (
              <Check
                className={css({ color: theme.colors.positive400 })}
                {...props}
              />
            )}
            artworkSize={ARTWORK_SIZES.LARGE}
          >
            <ListItemLabel>
              Наличие счета в банке, для перечисления заемных средств платежом
            </ListItemLabel>
          </ListItem>
          <ListItem
            artwork={(props) => (
              <Check
                className={css({ color: theme.colors.positive400 })}
                {...props}
              />
            )}
            artworkSize={ARTWORK_SIZES.LARGE}
          >
            <ListItemLabel>
              Не зарегистрирован в качестве индивидуального предпринимателя
            </ListItemLabel>
          </ListItem>
        </HeadingLevel>

        <HeadingLevel>
          <Heading styleLevel={4}>Что это за Фонд?</Heading>
        </HeadingLevel>
        <Paragraph1>
          Фонд учрежден Министерством экономического развития, промышленности и
          науки Архангельской области.
        </Paragraph1>
        <Paragraph1>
          Микрозаймы для самозанятых предоставляются без залога, под
          поручительства физических или юридических лиц.
        </Paragraph1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading styleLevel={4}>Могу ли я получить микрозаём?</Heading>
      </HeadingLevel>
      <CheckNPD title="Проверить возможность получения микрозайма" />
      <HeadingLevel>
        <Heading styleLevel={4}>
          Я подхожу под условия программы, как мне рассчитать платежи?
        </Heading>
        <Paragraph1>
          Воспользуйтесь{" "}
          <StyledLink to="/calculator" state={{ id: 7 }} $as={Link}>
            калькулятором
          </StyledLink>
          , чтобы рассчитать платежи по программе {name}
        </Paragraph1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading styleLevel={4}>Как подать заявку?</Heading>
        <Paragraph1>
          Для подачи заявки требуется собрать пакет документов, подробнее в
          разделе{" "}
          <StyledLink to="/apply" state={{ id: "3" }} $as={Link}>
            «Подать заявку»
          </StyledLink>
        </Paragraph1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading styleLevel={4}>У меня остались вопросы</Heading>
        <Paragraph1>
          Получите консультацию финансового эксперта по телефону{" "}
          <StyledLink href="tel:+78182464122">(8182) 46-41-22</StyledLink>, либо
          задайте вопросы по электронной почте{" "}
          <StyledLink href="mailto:expert@cmf29.ru?subject=Вопросы по займу для самозанятого">
            expert@cmf29.ru
          </StyledLink>{" "}
          .
        </Paragraph1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading styleLevel={4}>
          Я не подхожу под условия программы, но хочу получить деньги на
          развитие бизнеса
        </Heading>
        <Paragraph1>
          Если вы являетесь субъектом малого и среднего предпринимательства,
          можете рассмотреть другие{" "}
          <StyledLink to="/programs" $as={Link}>
            программы микрокредитования
          </StyledLink>
          .
        </Paragraph1>
      </HeadingLevel>
    </Page>
  );
};

export default SelfEmployed;
