const { keyRate } = require("../data/keyRate");

// drop more than 2 digits after a floating point
const roundHelper = (number) => Math.floor(number * 100) / 100;

const PROGRAMS = [
  {
    id: 0,
    name: "«5-5-10»",
    description:
      "Программа предоставления микрозаймов субъектам малого и среднего предпринимательства, осуществляющим основные виды экономической деятельности в приоритетных направлениях экономики Архангельской области.",
    interestRate: 10,
    loanTerm: 60,
    maxGracePeriod: 36,
    okved: {
      any: false,
      main: true,
      allow: [
        "01",
        "02",
        "03",
        "08",
        "10",
        "11.05",
        "11.06",
        "11.07",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "35",
        "36",
        "37",
        "38",
        "39",
        "41",
        "42",
        "43",
        "49.31.2",
        "49.32",
        "49.39",
        "49.41.1",
        "49.41.2",
        "49.42",
        "49.50.2",
        "49.50.3",
        "50.10.2",
        "50.20.2",
        "50.20.4",
        "50.30.1",
        "50.40.1",
        "50.40.2",
        "52.10",
        "52.21",
        "52.22",
        "52.24",
        "55",
        "56",
        "61",
        "62",
        "63",
        "71",
        "72",
        "75",
        "79.1",
        "80.2",
        "81",
        "82.3",
        "85",
        "86",
        "87",
        "88",
        "89.92",
        "90.03",
        "90.04",
        "91.02",
        "91.03",
        "91.04.1",
        "91.04.6",
        "93.1",
        "93.21",
        "93.29",
        "96.01",
        "96.04",
      ],
      deny: [],
    },
    links: {
      self: {
        href: "/programs/5-5-10",
      },
    },
    active: true,
  },
  {
    id: 1,
    name: "«Арктика-МСП»",
    description:
      "Субъекты малого и среднего предпринимательства Архангельской области, зарегистрированные в муниципальных образованиях  «Лешуконский муниципальный район», «Мезенский муниципальный район», «Онежский муниципальный район», «Пинежский муниципальный район»,«Приморский муниципальный район», «Новая Земля».",
    interestRate: roundHelper(keyRate / 2),
    okved: {
      any: true,
    },
    links: {
      self: {
        href: "/programs/arktika",
      },
    },
    active: true,
  },
  {
    id: 2,
    name: "«Лизинг-МСП»",
    description:
      "Субъекты малого и среднего предпринимательства Архангельской области, заключившие договор лизинга, либо получившие заключение лизинговой компании (оригинал) об одобрении лизинговой сделки (с указанием ее параметров).",
    interestRate: keyRate - 0.25,
    okved: {
      any: true,
    },
    links: {
      self: {
        href: "/programs/lizing",
      },
    },
    active: true,
  },
  {
    id: 3,
    name: "«Местный товаропроизводитель»",
    description:
      "Субъекты малого и среднего предпринимательства Архангельской области, осуществляющие деятельность в сфере обрабатывающего производства (не осуществляющие производство и реализацию подакцизных товаров (не проводящие операции, предусмотренные ст. 182 НК РФ) (кроме класса 33 по ОКВЭД)",
    interestRate: keyRate - 0.25,
    okved: {
      any: false,
      main: false,
      allow: [],
      deny: ["33"],
    },
    links: {
      self: {
        href: "/programs/mestnyi-tovaroproizvoditel",
      },
    },
    active: true,
  },
  {
    id: 4,
    name: "«Моногород-МСП»",
    description:
      "Субъекты малого и среднего предпринимательства Архангельской области, зарегистрированные и осуществляющие предпринимательскую деятельность на территории монопрофильных муниципальных образований (моногородов) Архангельской области, включенных в перечень моногородов; -не являющиеся градообразующими организациями соответствующего монопрофильного образования (моногорода);-не осуществляющие тот же основной вид экономической деятельности, который осуществляет градообразующая организация соответствующего монопрофильного муниципального образования.",
    interestRate: roundHelper(keyRate / 2),
    okved: {
      any: true,
    },
    links: {
      self: {
        href: "/programs/monogorod",
      },
    },
    active: true,
  },
  {
    id: 5,
    name: "«Рефинансирование-МСП»",
    description:
      "Субъекты малого и среднего предпринимательства Архангельской области, имеющие действующие кредиты (займы) в кредитных и иных организациях, предоставленные на цели ведения предпринимательской деятельности.",
    interestRate: keyRate,
    okved: {
      any: true,
    },
    links: {
      self: {
        href: "/programs/refinansirovanie",
      },
    },
    active: true,
  },
  {
    id: 6,
    name: "«Рециклинг»",
    description:
      "Субъекты малого и среднего предпринимательства Архангельской области, осуществляющие основные виды деятельности, относящиеся к классу 38 «Сбор, обработка и утилизация отходов; обработка вторичного сырья» по ОКВЭД, за исключением видов деятельности, отнесенных к подгруппам 38.32.2, 38.32.3 и 38.32.4.",
    interestRate: keyRate - 1.5,
    okved: {
      any: false,
      main: false,
      allow: ["38"],
      deny: ["38.32.2", "38.32.3", "38.32.4"],
    },
    links: {
      self: {
        href: "/programs/recycling",
      },
    },
    active: true,
  },
  {
    id: 7,
    name: "«Самозанятые граждане»",
    description:
      "Физические лица, осуществляющие предпринимательскую деятельность и применяющие специальный налоговый режим «Налог на профессиональный доход» на территории Архангельской области (самозанятые граждане)",
    interestRate: roundHelper(keyRate / 2),
    maxAmount: 500_000,
    okved: {
      any: true,
    },
    links: {
      self: {
        href: "/programs/samozanyatye",
      },
    },
    active: false,
  },
  {
    id: 8,
    name: "«Сельхозпроизводитель»",
    description:
      "Субъекты малого и среднего предпринимательства Архангельской области, осуществляющие деятельность в сфере сельского хозяйства; охоты, рыболовства и рыбоводства; сбора и заготовки пищевых лесных ресурсов, недревесных лесных ресурсов и лекарственных растений; производства готовых кормов для животных, содержащихся на фермах (классы 01 и 03; подкласс 02.3, группа 10.91 по ОКВЭД).",
    interestRate: keyRate - 0.5,
    okved: {
      any: false,
      main: false,
      allow: ["01", "03", "02.3", "10.91"],
      deny: [],
    },
    links: {
      self: {
        href: "/programs/agriculture",
      },
    },
    active: true,
  },
  {
    id: 9,
    name: "«Социальное предпринимательство»",
    description:
      "Субъекты малого и среднего предпринимательства Архангельской области, включенные министерством экономического развития, промышленности и науки Архангельской области в «Перечень субъектов малого и среднего предпринимательства, имеющих статус социального предприятия в Архангельской области» на дату заключения договора займа.",
    interestRate: roundHelper(keyRate / 2),
    okved: {
      any: true,
    },
    links: {
      self: {
        href: "/programs/socialnoe-predprinimatelstvo",
      },
    },
    active: true,
  },
  {
    id: 10,
    name: "«Стандарт»",
    description:
      "Субъекты малого и среднего предпринимательства Архангельской области, предпринимательская деятельность которых не запрещена Правилами, не включенная в другие категории",
    interestRate: keyRate,
    okved: {
      any: true,
    },
    links: {
      self: {
        href: "/programs/standard",
      },
    },
    active: true,
  },
  {
    id: 11,
    name: "«Туризм»",
    description:
      "Субъекты малого и среднего предпринимательства Архангельской области, осуществляющие деятельность в сфере туризма (классы 47.78.5, 49.39.11, 49.39.2, 55.1, 55.10, 55.2, 55.20, 56.1, 86.90.4, 91.02 по ОКВЭД).",
    interestRate: roundHelper(keyRate / 2),
    okved: {
      any: false,
      main: false,
      allow: [
        "47.78.5",
        "49.39.11",
        "49.39.2",
        "55.1",
        "55.10",
        "55.2",
        "55.20",
        "56.1",
        "86.90.4",
        "91.02",
      ],
      deny: [],
    },
    links: {
      self: {
        href: "/programs/tourism",
      },
    },
    active: true,
  },
  {
    id: 12,
    name: "«Экспресс-заём»",
    description:
      "Индивидуальные предприниматели, деятельность которых не запрещена Правилами",
    interestRate: keyRate,
    loanTerm: 12,
    isGraceDisabled: true,
    maxAmount: 100_000,
    okved: {
      any: true,
    },
    links: {
      self: {
        href: "/programs/express",
      },
    },
    active: true,
  },
];

module.exports = { PROGRAMS };
